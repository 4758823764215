.App {
  display: flex;
  width: 100%;
  height: 100vh;
}

.editor {
  margin-left: 10px;
}

.Header {
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 999;
}

.error-message {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #2882d6;
  background: #f3f3f3;
}
.ck-editor__main i {
  font-style: italic !important;
  font-family: sans-serif !important;
}
.ck-editor__main ul,
ol {
  padding: revert !important;
}

.ck-editor__main h2 {
  display: block !important;
  font-size: 1.5em !important;
  margin-block-start: 0.83em !important;
  margin-block-end: 0.83em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  font-weight: bold !important;
}
.ck-editor__main h3 {
  display: block !important;
  font-size: 1.17em !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  font-weight: bold !important;
}

.ck-editor__main h4 {
  display: block !important;
  margin-block-start: 1.33em !important;
  margin-block-end: 1.33em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  font-weight: bold !important;
}
.ka-table-wrapper {
  height: 90vh !important;
}

.container-wrapper {
  display: flex;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  padding: 40px;
  background-color: white;
}

.container-title {
  display: flex;
  font-size: xx-large;
  width: 100%;
  height: 50px;
  color: black;
}

.container-underline {
  display: flex;
  height: 1px;
  background-color: #e6e6e6;
  margin-right: 100px;
  margin-bottom: 10px;
}
.container-form-submit {
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;
}

.container-form-date-picker {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  height: 60px;
  align-items: center;
  margin-bottom: 20px;
}

.container-form-lable {
  font-size: 20px;
  color: black;
}

.container-form-radio-buttons {
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 60px;
  align-items: center;
  margin-bottom: 20px;
}

.MuiButton-colorInherit {
  color: white !important;
  background-color: #2882d6 !important;
}

.MuiRadio-root {
  color: #2882d6 !important;
}

.container-form-submit .MuiButton-outlined {
  border: 1px solid rgb(40 130 214) !important;
  padding: 5px 15px;
}
.container-form-submit .MuiButton-outlined:hover {
  border: 1px solid rgb(40 130 214) !important;
  color: rgb(40 130 214) !important;
}
.container-form-submit .MuiButton-outlined > a:hover {
  color: rgb(255, 255, 255) !important;
}

.watch-party-form-container {
  display: grid;
  width: 100%;
}

.watch-party-form {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 100px;
  margin-bottom: 20px;
}

.watch-party-form-element {
  display: grid;
}

label {
  color: black !important;
  margin-bottom: 5px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #d1d1d1 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #d1d1d1 !important;
  border-width: 1px !important;
}
/* .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline legend {
  display: none !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline legend span {
  visibility: hidden !important;
} */

.MuiInputLabel-outlined {
  color: grey !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #2882d6 !important;
}

sup {
  color: red;
}

.watch-party-add-user {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.watch-party-add-user > span {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: rgb(40 130 214) !important;
}
.watch-party-add-user > span > svg {
  color: rgb(40 130 214) !important;
}

.watch-party-user-details {
  display: grid;
  grid-template-columns: 0.5fr 2fr 2fr 5fr;
  gap: 20px;
  margin-bottom: 30px;
  margin-right: 25px;
}

.watch-party-user-details > div:first-child {
  margin-top: 19px;
}

.watch-party-user-email {
  display: flex;
  align-items: center;
  gap: 5px;
}

.watch-party-user-email,
.action-button > svg {
  color: rgb(40 130 214) !important;
  cursor: pointer;
}

.room-listing-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 10px;
  margin-right: 25px;
}

.search-container {
  align-items: center;
  justify-content: space-between;
  padding-right: 100px;
  margin-bottom: 25px;
}

.room-listing-details-item {
  font-weight: 400;
  align-items: center;
}

.send-email {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-fuzzy-search > div {
  padding: 0px !important;
  box-shadow: none !important;
}

.react-fuzzy-search > div:nth-child(2) {
  position: absolute !important;
  width: 460px !important;
  box-shadow: -1px 6px 13px 0px rgba(0, 0, 0, 0.2) !important;
  z-index: 10 !important;
}

.override-form {
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 60px;
  align-items: center;
  margin-bottom: 40px;
}

.override-field-elements {
  display: grid;
}

.override-field-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.3fr;
  gap: 20px;
  margin-bottom: 30px;
  margin-right: 25px;
}

.override-field-elements {
  display: grid;
}

.override-add-icon {
  display: flex;
  align-items: flex-end;
}

.override-add-icon > span {
  margin-bottom: 12px;
  cursor: pointer;
}

.override-form-submit {
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;
}

.override-form-submit button {
  width: 6rem !important;
}

.event-listing {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-bottom: 10px;
  margin-right: 25px;
}

.event-listing-item {
  font-weight: 400;
  align-items: center;
}

.event-heading {
  font-size: 22px;
  margin-bottom: 20px;
}

.event-trigger-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-trigger-text {
  font-size: 16px;
  margin-top: 15px;
}

.block {
  display: block;
  width: 11%;
  border: none;
  background-color: #0082d6;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 35px;
  border-radius: 7px;
}

.block:hover {
  background-color: #ddd;
  color: black;
}

.no-event {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 51px;
  margin-bottom: 61px;
}

reminder-listing-item {
  font-weight: 400;
  align-items: center;
}

.cms-datetime {
  padding-top: 15px;
}

.cms-datetime input {
  width: 100%;
  height: 32px;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  font-weight: 400;
  padding: 0px 8px;
}

.cms-datetime > div {
  width: 100%;
}

.download_links {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
